
import dayjs from 'dayjs';
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component
export default class LastUpdated extends mixins(AppName, ConstantsMixin) {
  @Prop() lastUpdatedTimestamp!: number | null;
  public lastUpdatedDisplayValue: string | null = null;

  @Watch('lastUpdatedTimestamp')
  timestampUpdated() {
    this.updateDisplayValue();
  }

  @Emit('refreshData')
  refreshData() {
    this.lastUpdatedDisplayValue = 'Updating';
    return;
  }

  mounted() {
    this.updateDisplayValue();
    setInterval(this.updateDisplayValue, 60000);
  }

  public updateDisplayValue() {
    if (this.lastUpdatedTimestamp) {
      this.lastUpdatedDisplayValue =
        'Updated ' + dayjs.unix(this.lastUpdatedTimestamp).fromNow();
    }
  }
}
