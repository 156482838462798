
import AppName from '@/components/mixin/AppName.vue';
import ConstantsMixin from '@/components/mixin/Constants.vue';
import { getListItem, setListItem } from '@/utils/LocalStorageUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class AlertsFilters extends mixins(ConstantsMixin, AppName) {
  @Prop() propStateFilter!: string[];
  @Prop() propShowAll!: boolean;
  public showAll = true;
  public stateFilter: string[] = ['unactioned', 'actioned'];
  public alertType: string[] = ['score'];

  mounted() {
    const savedAlertType = getListItem('milkAlertType');
    if (savedAlertType && savedAlertType.length > 0) {
      this.alertType = savedAlertType;
    }
  }

  get hasFiltersApplied() {
    const stateFilterApplied =
      !this.stateFilter.includes('unactioned') ||
      !this.stateFilter.includes('actioned');
    const alertsFilterApplied =
      !this.isApp(this.PRODUCT_TYPE_MILK) && !this.showAll;

    return stateFilterApplied || alertsFilterApplied;
  }

  public changingAlertType(newAlertType: string[]) {
    setListItem('milkAlertType', newAlertType);
    this.$emit('alertFiltersUpdated', this.showAll, this.stateFilter);
  }

  public changingShowAll(newValue: boolean) {
    this.$emit('alertFiltersUpdated', newValue, this.stateFilter);
  }

  public changingStateFilter(newStateFilter: string[]) {
    this.$emit('alertFiltersUpdated', this.showAll, newStateFilter);
  }
}
