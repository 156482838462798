
import RepairIcon from '@/components/common/RepairIcon.vue';
import { Product } from '@/store/models/Product';
import {
  formatCurrentUsageDisplay,
  formatUsageTodayDisplay,
  formatUsageYesterdayDisplay
} from '@/utils/formatters/DisplayValueFormatters';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  components: { RepairIcon },
  methods: {
    formatCurrentUsageDisplay,
    formatUsageTodayDisplay,
    formatUsageYesterdayDisplay
  }
})
export default class WaterCardList extends Vue {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() loadingProducts!: boolean;

  public didSelectCard(selectedItems: Product[], id: number, vatId: number) {
    this.$emit('card-selected', selectedItems, id, vatId);
  }
}
