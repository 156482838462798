
import FuelAlertsCardList from '@/components/list/FuelAlertsCardList.vue';
import { Product } from '@/store/models/Product';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import PaginationContainer from '../common/PaginationContainer.vue';
import ScrollableContainer from '../common/ScrollableContainer.vue';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import ActionAlertModal from '../modal/ActionAlertModal.vue';
import EditCommentModal from '../modal/EditCommentModal.vue';
import FeedAlertsCardList from './FeedAlertsCardList.vue';
import MilkAlertsCardList from './MilkAlertsCardList.vue';
import WaterAlertsCardList from './WaterAlertsCardList.vue';

@Component({
  components: {
    FuelAlertsCardList,
    MilkAlertsCardList,
    FeedAlertsCardList,
    WaterAlertsCardList,
    ActionAlertModal,
    EditCommentModal,
    ScrollableContainer,
    PaginationContainer
  }
})
export default class AlertsCardList extends mixins(ConstantsMixin, AppName) {
  @Prop() products!: Product[];
  @Prop() totalProducts!: number;
  @Prop() loadingProducts!: boolean;
  @Prop() offset!: number;
  @Prop() perPageLimit!: number;

  public actionProduct: Product | null = null;

  public didSelectCard(selectedItems: Product[], id: number, entityId: number) {
    this.$emit('card-selected', selectedItems[0]);
  }

  public alertsUpdated() {
    this.$emit('alertsUpdated');
  }

  public getMaxHeight() {
    return `max-height:calc(100% - ${(this.$refs[
      'alertsCardList'
    ] as HTMLElement)?.getBoundingClientRect()?.top - 56}px);`;
  }

  public openActionModal(product: Product) {
    this.actionProduct = product;
    setTimeout(() => {
      this.$root.$emit(
        'bv::show::modal',
        `${product.productId}_${product.entityId}actionAlertModal`,
        '#btnShow'
      );
    }, 200);
  }
  public openEditModal(product: Product) {
    this.actionProduct = product;
    setTimeout(() => {
      this.$root.$emit(
        'bv::show::modal',
        `${product.productId}_${product.entityId}editCommentModal`,
        '#btnShow'
      );
    }, 200);
  }
}
