
import { Component, Inject, Prop, PropSync, Vue } from 'vue-property-decorator';
import RepairIcon from '@/components/common/RepairIcon.vue';

import { Product } from '@/store/models/Product';
import { PermissionService } from '@/services/PermissionService';

@Component({ components: { RepairIcon } })
export default class WaterAlertsCardList extends Vue {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() maxTank!: number;
  @Prop() loadingProducts!: boolean;

  @Inject('permissionService')
  private permissionService!: PermissionService;

  public get canActionAlerts(): boolean {
    return this.permissionService.canActionAlerts;
  }

  public onEdit(item: Product): void {
    if (!this.canActionAlerts) {
      return;
    }
    this.openEditModal(item);
  }

  public onAction(item: Product): void {
    if (!this.canActionAlerts) {
      return;
    }
    this.openActionModal(item);
  }

  public didSelectCard(selectedItems: Product[], id: number, entityId: number) {
    this.$emit('card-selected', selectedItems, id, entityId);
  }

  public openActionModal(product: Product) {
    this.$emit('openActionModal', product);
  }

  public openEditModal(product: Product) {
    this.$emit('openEditModal', product);
  }
}
