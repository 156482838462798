
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import Pagination from './Pagination.vue';

@Component({
  components: {
    Pagination
  }
})
export default class PaginationContainer extends mixins(
  AppName,
  ConstantsMixin
) {
  @Prop() totalProducts!: number;
  @Prop() offset!: number;
  @Prop() perPageLimit!: number;
  @Prop({ default: false }) disablePerPageChoice!: boolean;
  @Prop() isTable!: boolean;
  public pagesList: (number | null)[] = [];
  public perPage = this.perPageLimit;

  mounted() {
    this.updatePagesList();
  }
  @Watch('totalProducts')
  @Watch('offset')
  updatePageList() {
    this.updatePagesList();
  }

  @Watch('perPage')
  perPageUpdated() {
    this.updatePagesList();
    this.$emit('perPageUpdated', this.perPage);
  }

  @Watch('perPageLimit')
  perPageLimitUpdated() {
    this.perPage = this.perPageLimit;
  }

  get upperBoundOffset() {
    return this.offset + this.perPageLimit > this.totalProducts
      ? this.totalProducts
      : this.offset + this.perPageLimit;
  }

  public updatePagesList() {
    const pages = Math.ceil(this.totalProducts / this.perPage);
    const pagesList: (number | null)[] = [];
    if (pages <= 6) {
      let i;
      for (i = 1; i <= pages; i++) {
        pagesList.push(i);
      }

      this.pagesList = pagesList;
    } else {
      const currentPage = this.offset / this.perPage + 1;
      pagesList.push(1);

      let i;
      for (i = currentPage - 3; i <= currentPage + 3; i++) {
        if (i > 1 && i < pages) {
          if (i == currentPage - 3 && i != 2) {
            pagesList.push(null);
          } else if (i == currentPage + 3 && i != pages - 1) {
            pagesList.push(null);
          } else {
            pagesList.push(i);
          }
        }
      }
      pagesList.push(pages);
      this.pagesList = pagesList;
    }
  }
}
