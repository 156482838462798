
import RepairIcon from '@/components/common/RepairIcon.vue';
import { Product } from '@/store/models/Product';
import { getFormattedFeedAmount } from '@/utils/FeedUtils';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({
  components: {
    RepairIcon
  },
  methods: {
    getFormattedFeedAmount
  }
})
export default class FeedCardList extends mixins(AppName) {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() loadingProducts!: boolean;

  public didSelectCard(selectedItems: Product[], id: number, vatId: number) {
    this.$emit('card-selected', selectedItems, id, vatId);
  }

  public isMultiLineFeedType(type: string) {
    return type.includes('/');
  }

  public getFeedType(type: string) {
    if (type.includes('/')) {
      return type.slice(0, 7);
    } else {
      return type.slice(0, 3);
    }
  }
}
