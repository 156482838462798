
import AppName from '@/components/mixin/AppName.vue';
import TableSearchbar from '@/components/table/TableSearchbar.vue';
import { goToSiteRequest } from '@/utils/FuelUtils';
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
@Component({
  components: {
    TableSearchbar
  },
  methods: {
    goToSiteRequest
  }
})
export default class Searchbar extends mixins(AppName) {
  @Prop() placeholder!: string;
  @Prop() isTable!: boolean;
  @Prop() searchTerm!: string;
  @Prop() propActiveSearch?: boolean;
  public activeSearch = false;

  mounted() {
    this.activeSearch = this.propActiveSearch ?? false;
  }

  @Emit('search')
  public search(searchTerm: string) {
    return searchTerm;
  }

  @Emit('activeSearchUpdated')
  updateActiveSearch(activeSearch: boolean) {
    this.activeSearch = activeSearch;
  }

  @Watch('propActiveSearch')
  activeSearchUpdated() {
    this.activeSearch = this.propActiveSearch ?? false;
  }

  public activateSearch() {
    this.updateActiveSearch(true);
  }

  public deactivateSearch() {
    this.updateActiveSearch(false);
    this.search('');
  }
}
