var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"containerInner px-2 mb-5"},[(_vm.sortedProducts || _vm.sortedProducts.length != 0)?_c('div',{staticClass:"milkListView overflow-y-auto"},[(_vm.sortedProducts.length == 0)?_c('div',{staticClass:"pt-4"},[_c('p',[_vm._v("No products to show")])]):_vm._e(),_vm._l((_vm.combinedValues),function(item){return _c('div',{key:item.productId,ref:"list",refInFor:true,staticClass:"my-3"},[_c('BCard',{staticClass:"w-100 p-0 text-left fuelProductCard border-none",attrs:{"bg-variant":"white"}},[_c('BCardHeader',{staticClass:"py-1 px-2"},[_c('BRow',{staticClass:"py-1"},[_c('BCol',{staticClass:"pr-0",attrs:{"cols":"9"}},[_c('p',{staticClass:"mb-0 fuelProductName textOverflowEllipsis",attrs:{"title":item.siteName}},[_vm._v(" "+_vm._s(item.siteName.toUpperCase())+" "),_c('RepairIcon',{attrs:{"item":item,"classAlt":"ml-1"}})],1)]),(item.supplierNumber)?_c('BCol',{attrs:{"cols":"3"}},[_c('p',{staticClass:"mb-0 text-right textOverflowEllipsis"},[_vm._v(" "+_vm._s(item.supplierNumber ? item.supplierNumber : '')+" ")])]):_vm._e()],1)],1),_c('div',{staticClass:"w-100 text-left border-none",on:{"click":function($event){return _vm.didSelectCard([item], item.productId, -1)}}},[_c('BRow',{staticClass:"mx-0 py-1",class:item.metrics.state != 'supply' ? 'text-mediumGrey' : ''},[_c('BCol',{staticClass:"my-auto col30 px-1"},[_c('p',{staticClass:"mb-0 py-1 my-auto text-center"},[(item.metrics.state != 'supply')?_c('img',{attrs:{"src":require("@/assets/milk_not_supplying.svg"),"width":"13","height":"14","alt":"Age of milk","title":`The ${_vm.getEntityName(false)}/s on this site are not ${
                      item.metrics.state == 'notUsed'
                        ? 'in use'
                        : 'not supplying milk'
                    }`}}):(
                    _vm.milkScore(item) &&
                      _vm.milkScore(item).rating &&
                      _vm.milkScore(item).rating > 0
                  )?_c('span',[(
                      _vm.milkScore(item).complianceMethod ==
                        _vm.COMPLIANCE_METHOD_FONTERRA
                    )?_c('span',{staticClass:"text-secondary text-center py-1 rounded font-weight-bold",staticStyle:{"font-size":"10px"},attrs:{"title":item.supplier == 'F'
                        ? 'Milk Quality Indicator (MQI)'
                        : 'This value is the degrees out of spec times the minutes at that temperature'}},[_vm._v(" "+_vm._s(_vm.formatMilkScoreDisplay(item, _vm.milkScore(item)))+" ")]):_c('img',{staticClass:"ml-1",attrs:{"src":_vm.getImage(
                        `milk_${_vm.formatMilkScoreColour(
                          _vm.milkScore(item).rating,
                          !!_vm.milkScore(item).areaOutOfSpec
                        )}_score`
                      ),"width":"16","height":"16","alt":"Milk Score Warning","title":`The milk score is at ${_vm.formatMilkScoreColour(
                        _vm.milkScore(item).rating,
                        !!_vm.milkScore(item).areaOutOfSpec
                      )} for ${_vm.complianceMethodLabel(
                        _vm.milkScore(item).complianceMethod
                      )}`}})]):(
                    item.metrics.lastMilking &&
                      item.metrics.lastMilking.type == 'start'
                  )?_c('img',{attrs:{"src":require("@/assets/milk-milking.svg"),"title":"This site currently has milking in progress","width":"15","height":"14"}}):_c('img',{attrs:{"src":require("@/assets/milk-vat-grey.svg"),"width":"13","height":"14"}})])]),_c('BCol',{staticClass:"col120 pr-1 pl-0 my-auto"},[_c('p',{staticClass:"mb-0 py-1 my-auto textOverflowEllipsis"},[_c('span',{staticClass:"text-bold px-1"},[_vm._v(_vm._s(item.metrics.containsMilk ? Math.round( (item.metrics.vatVolume / item.metrics.capacity) * 100 ) : 0)+"%")]),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(item.metrics.vatVolume && item.metrics.capacity ? item.metrics.containsMilk ? `${Math.round( item.metrics.vatVolume ).toLocaleString()} / ${Math.round( item.metrics.capacity ).toLocaleString()}` : item.metrics.containsMilk == null ? 'N/A' : 'Empty' : item.metrics.vatVolume == null || item.metrics.capacity == null ? 'N/A' : 'Empty'))])])]),_c('BCol',{staticClass:"col40 px-0 my-auto text-secondary"},[_c('p',{staticClass:"mb-0 py-1 text-center my-auto"},[(
                    item.metrics.milkEnteredVat &&
                      item.metrics.containsMilk &&
                      _vm.formatHours(item.metrics.milkEnteredVat) > 72
                  )?_c('img',{attrs:{"src":require("@/assets/milk_age_warning.svg"),"width":"12","height":"12","alt":"Age of milk","title":"The age of milk is above 72 hours"}}):_vm._e(),(_vm.isMilkTempColumnPartnerId())?_c('span',_vm._l((item.entities),function(vat){return _c('span',{key:vat.entityId},[(
                        vat.metrics.containsMilk &&
                          vat.metrics.vatTemperature < 6
                      )?_c('BIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"check","variant":vat.metrics.state != 'supply'
                          ? 'text-mediumGrey'
                          : 'secondary',"title":"Milk is under 6 degrees"}}):_vm._e(),(
                        vat.metrics.containsMilk &&
                          vat.metrics.vatTemperature >= 6
                      )?_c('BIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"thermometer-half","variant":vat.metrics.state != 'supply'
                          ? 'text-mediumGrey'
                          : 'secondary',"title":"Milk is over 6 degrees"}}):_vm._e()],1)}),0):_vm._e()])]),_c('BCol',{staticClass:"my-auto col40 px-1"},[_c('p',{staticClass:"mb-0 py-1 my-auto text-center font-weight-bold"},[_vm._v(" "+_vm._s(item.metrics.milkEnteredVat && item.metrics.containsMilk ? _vm.formatAgeMilk(item.metrics.milkEnteredVat) : item.metrics.containsMilk == null ? 'N/A' : '')+" ")])]),_c('BCol',{staticClass:"my-auto text-right px-1"},[_c('p',{staticClass:"milkingText"},[_vm._v(" Milking: ")]),_c('p',{staticClass:"my-auto lastMilkingText",class:item.metrics.lastMilking &&
                  item.metrics.lastMilking.type == 'start'
                    ? 'text-secondary'
                    : ''},[_vm._v(" "+_vm._s(item.metrics.lastMilking ? item.metrics.lastMilking.type == 'start' ? 'In Progress' : _vm.formatLastMilking(item.metrics.lastMilking.timestamp) : '11:50am 3 Apr')+" ")])])],1)],1),(item.entities.length != 1 && item.productId == _vm.expanded)?_c('BCollapse',{attrs:{"visible":item.productId == _vm.expanded}},_vm._l((item.entities),function(vat,index){return _c('div',{key:index},[_c('hr',{staticClass:"my-0"}),_c('BRow',{staticClass:"mx-0",class:vat.metrics.state != 'supply' ? 'text-mediumGrey' : '',on:{"click":function($event){return _vm.didSelectCard([item], item.productId, index)}}},[_c('BCol',{staticClass:"col30 px-1"},[(index != item.entities.length - 1)?_c('div',{staticClass:"h-100",staticStyle:{"flex":"1 1 auto","display":"flex","flex-direction":"column","justify-content":"center","align-items":"flex-start","margin-left":"-10px"}},[_c('img',{attrs:{"src":require("../../assets/milkMiddleLinked.svg"),"height":"34px"}})]):_vm._e(),(
                    index == item.entities.length - 1 &&
                      item.entities.length != 1
                  )?_c('div',{staticClass:"h-100",staticStyle:{"flex":"1 1 auto","display":"flex","flex-direction":"column","justify-content":"center","align-items":"flex-start","margin-left":"-10px"}},[_c('img',{attrs:{"src":require("../../assets/milkEndLinked.svg"),"height":"34px"}})]):_vm._e()]),_c('BCol',{staticClass:"col120 pr-1 pl-0 my-auto"},[_c('p',{staticClass:"mb-0 py-1 my-auto textOverflowEllipsis"},[_c('span',{staticClass:"text-bold px-1"},[_vm._v(_vm._s(vat.metrics.containsMilk ? Math.round( (vat.metrics.vatVolume / vat.capacity) * 100 ) : 0)+"%")]),_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(vat.metrics.vatVolume && vat.capacity ? vat.metrics.containsMilk ? `${Math.round( vat.metrics.vatVolume ).toLocaleString()} / ${Math.round( vat.capacity ).toLocaleString()}` : vat.metrics.containsMilk == null ? 'N/A' : 'Empty' : vat.metrics.vatVolume == null || vat.capacity == null ? 'N/A' : 'Empty'))])])]),_c('BCol',{staticClass:"col40 px-0 my-auto text-secondary"},[_c('p',{staticClass:"mb-0 py-1 my-auto text-center"},[(
                      vat.metrics.milkScore &&
                        vat.metrics.milkScore >= 1 &&
                        vat.metrics.containsMilk &&
                        item.supplier != 'F'
                    )?_c('span',{staticClass:"text-secondary text-center py-1 rounded font-weight-bold",staticStyle:{"font-size":"10px"},attrs:{"title":"This value is the degrees out of spec times the minutes at that temperature"}},[_vm._v(" "+_vm._s(Math.round(vat.metrics.milkScore))+" ")]):_vm._e(),(
                      vat.metrics.milkScore &&
                        vat.metrics.milkScore >= 1 &&
                        vat.metrics.containsMilk &&
                        item.supplier == 'F'
                    )?_c('span',{staticClass:"text-secondary text-center py-1 rounded font-weight-bold",staticStyle:{"font-size":"10px"},attrs:{"title":`Milk Quality Indicator (MQI) | ${_vm.formatMilkScore(
                        vat.metrics.milkScore
                      )}`}},[_vm._v(" "+_vm._s(_vm.formatMilkScoreAbbr(vat.metrics.milkScore))+" ")]):_vm._e(),(
                      vat.metrics.milkEnteredVat &&
                        vat.metrics.containsMilk &&
                        _vm.formatHours(vat.metrics.milkEnteredVat) > 72
                    )?_c('img',{attrs:{"src":require("@/assets/milk_age_warning.svg"),"width":"16","height":"16","alt":"Age of milk","title":"The age of milk is above 72 hours"}}):_vm._e(),(vat.metrics.state != 'supply')?_c('img',{attrs:{"src":require("@/assets/milk_not_supplying.svg"),"width":"13","height":"14","alt":"Age of milk","title":`The ${_vm.getEntityName(false)}/s on this site are not ${
                        vat.metrics.state == 'notUsed'
                          ? 'in use'
                          : 'not supplying milk'
                      }`}}):_vm._e(),(_vm.isMilkTempColumnPartnerId())?_c('span',[(
                        vat.metrics.containsMilk &&
                          vat.metrics.vatTemperature < 6
                      )?_c('BIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"check","variant":vat.metrics.state != 'supply'
                          ? 'text-mediumGrey'
                          : 'secondary',"title":"Milk is under 6 degrees"}}):_vm._e(),(
                        vat.metrics.containsMilk &&
                          vat.metrics.vatTemperature >= 6
                      )?_c('BIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"icon":"thermometer-half","variant":vat.metrics.state != 'supply'
                          ? 'text-mediumGrey'
                          : 'secondary',"title":"Milk is over 6 degrees"}}):_vm._e()],1):_vm._e()])]),_c('BCol',{staticClass:"my-auto col40 px-1"},[_c('p',{staticClass:"mb-0 py-1 my-auto text-center font-weight-bold"},[_vm._v(" "+_vm._s(vat.metrics.milkEnteredVat && vat.metrics.containsMilk ? _vm.formatAgeMilk(vat.metrics.milkEnteredVat) : vat.metrics.containsMilk == null ? 'N/A' : '')+" ")])]),_c('BCol',{staticClass:"my-auto text-right px-1"},[_c('p',{staticClass:"milkingText"},[_vm._v(" Milking: ")]),_c('p',{staticClass:"my-auto lastMilkingText",class:vat.metrics.lastMilking &&
                    vat.metrics.lastMilking.type == 'start'
                      ? 'text-secondary'
                      : ''},[_vm._v(" "+_vm._s(vat.metrics.lastMilking ? vat.metrics.lastMilking.type == 'start' ? 'In Progress' : _vm.formatLastMilking(vat.metrics.lastMilking.timestamp) : '11:50am 3 Apr')+" ")])])],1)],1)}),0):_vm._e(),(item.entities.length != 1)?_c('hr',{staticClass:"my-0"}):_vm._e(),(item.entities.length != 1)?_c('div',{staticClass:"w-100 text-left border-none"},[_c('p',{staticClass:"p-2 m-0 text-center expandVatsButton",on:{"click":function($event){return _vm.toggleVats(item.productId)}}},[_vm._v(" "+_vm._s(item.productId == _vm.expanded ? `Hide` : `Expand ${item.entities.length} vats`)+" "),_c('BIcon',{attrs:{"icon":item.productId == _vm.expanded ? 'chevron-up' : 'chevron-down'}})],1)]):_vm._e()],1)],1)})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }