
import { Component, Emit, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TableSearchbar extends Vue {
  @Prop() placeholder!: string;
  @Prop() value?: string;
  @Prop() cross?: boolean;

  // Needed only for pages that use the responsive bootstrap classes 
  // instead of manually checking the window width. 
  // There can be two search bars in the DOM and so 
  // the getElementById below produces unexpected results
  @Prop({ required: false, type: String })
  public idValue?: string | null;

  @Emit('search')
  public valueUpdated(searchTerm: string): string {
    return searchTerm;
  }

  hideKeyboard() {
    (this.$refs.searchbar as HTMLElement).blur();
  }

  clickedSearchbar(event: PointerEvent) {
    if (
      (document.getElementById('searchBar')?.clientWidth ?? 180) - 35 <
      event.offsetX
    ) {
      this.$emit('unactivateSearch');
    }
  }
}
