
import RepairIcon from '@/components/common/RepairIcon.vue';
import { Product } from '@/store/models/Product';
import {
  formatWaterVolumeDisplay,
  formatWaterUllageDisplay,
  formatPercentWaterFullDisplay,
  formatWaterTankUnit
} from '@/utils/formatters/DisplayValueFormatters';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  components: { RepairIcon },
  methods: {
    formatWaterVolumeDisplay,
    formatWaterUllageDisplay,
    formatPercentWaterFullDisplay,
    formatWaterTankUnit
  }
})
export default class WaterCardList extends Vue {
  [x: string]: any;
  @PropSync('items') sortedProducts!: Product[];
  @Prop() loadingProducts!: boolean;

  public didSelectCard(selectedItems: Product[], id: number, vatId: number) {
    this.$emit('card-selected', selectedItems, id, vatId);
  }
}
