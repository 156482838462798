
import Vue from 'vue';
import MilkCardList from '@/components/list/MilkCardList.vue';
import { Product } from '@/store/models/Product';
import SortingInfo from '@/store/models/SortingInfo';
import { getMaxCapacity } from '@/utils/formatters/MilkEntitiesFormatter';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import Datatable from '../table/Datatable.vue';
import FeedCardList from './FeedCardList.vue';
import FuelCardList from './FuelCardList.vue';
import { setMaxVatVolume } from '@/store/Globals';
import { isPage } from '@/utils/PageUtils';
import ScrollableContainer from '../common/ScrollableContainer.vue';
import PaginationContainer from '../common/PaginationContainer.vue';
import WaterTankCardList from './WaterTankCardList.vue';
import WaterMeterCardList from './WaterMeterCardList.vue';
import { isWaterMeter, isWaterTank } from '@/utils/WaterUtils';

@Component({
  components: {
    Datatable,
    MilkCardList,
    FuelCardList,
    FeedCardList,
    WaterTankCardList,
    WaterMeterCardList,
    ScrollableContainer,
    PaginationContainer
  },
  methods: {
    isPage,
    isWaterTank,
    isWaterMeter
  }
})
export default class ListView extends mixins(AppName, ConstantsMixin) {
  @PropSync('items') syncedItems!: Product[];
  @Prop() mapCollapse!: boolean;
  @Prop() isTable!: boolean;
  @Prop() loadingProducts!: boolean;
  @Prop() totalProducts!: number;
  @Prop() offset!: number;
  @Prop() perPageLimit!: number;
  @Prop() active!: string;
  @Prop() tableColumns!: any[];
  @Prop() sortInfo!: SortingInfo;
  @Prop({ default: false }) disablePerPageChoice!: boolean;
  public topPosition = 553;
  public pagesList: (number | null)[] = [];
  public perPage = this.perPageLimit;

  @Watch('perPageLimit')
  perPageLimitUpdated() {
    this.perPage = this.perPageLimit;
  }

  public getTopPos() {
    return `max-height:calc(100% - ${this.topPosition}px);`;
  }

  get sortedProducts() {
    const productsClone = [...this.syncedItems];
    if (this.isApp(this.PRODUCT_TYPE_MILK)) {
      setMaxVatVolume(getMaxCapacity(productsClone));
    }

    return productsClone;
  }

  public perPageUpdated(perPage: number) {
    this.$emit('perPageUpdated', perPage);
  }

  public didSelectRow(item: Product, entityIndex: number) {
    this.$emit('row-selected', item, entityIndex);
  }

  public didSelectCard(selectedItems: Product[], id: number, vatId: number) {
    this.$emit('card-selected', selectedItems[0], vatId);
  }

  public updateSorting(newSortInfo: SortingInfo) {
    this.$emit('sorting-changed', newSortInfo);
  }
}
