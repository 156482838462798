
import { mixins } from 'vue-class-component';
import { Component, Model, Prop } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';

@Component
export default class Pagination extends mixins(AppName, ConstantsMixin) {
  @Prop() currentPage!: number;
  @Prop() pagesList!: (number | null)[];
  @Prop() totalProducts!: number;
  @Prop() perPageLimit!: number;

  public changeCurrentPage(isPrevious: boolean, exactPage = 0) {
    if (exactPage != null) {
      this.$root.$emit('offsetChanged', isPrevious, exactPage - 1);
    }
  }

  get upperBoundOffset() {
    return this.currentPage + this.perPageLimit > this.totalProducts
      ? this.totalProducts
      : this.currentPage + this.perPageLimit;
  }
}
