
import FilterTextInput from '@/components/filters/FilterTextInput.vue';
import { FetchFeedTypes } from '@/services/api/ProductApi';
import CustomField from '@/store/models/CustomField';
import FeedType from '@/store/models/FeedTypes';
import { FeedFilters, SavedFeedFilters } from '@/store/models/Filters';
import CustomStore from '@/store/modules/CustomStore';
import {
  getFeedAmount,
  getFeedAmountFilter,
  getFeedCapacityFilter
} from '@/utils/FeedUtils';
import { toTitleCase } from '@/utils/FormattingUtils';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';

@Component({
  components: { FilterTextInput },
  methods: {
    getFeedAmount,
    toTitleCase,
    getFeedAmountFilter,
    getFeedCapacityFilter
  }
})
export default class FeedFiltersModal extends mixins(AppName) {
  @Prop() selectedPropSiteFilters!: {
    field: CustomField;
    selection: string[];
  }[];
  @Prop() selectedPropTankFilters!: {
    field: CustomField;
    selection: string[];
  }[];
  @Prop() propFilters!: FeedFilters;
  @Prop() maxVolume!: number;
  @Prop() maxCapacity!: number;
  @Prop() maxUllage!: number;
  @Prop() width!: number;

  public selectedSiteFilters: {
    field: CustomField;
    selection: string[];
  }[] = [];
  public selectedTankFilters: {
    field: CustomField;
    selection: string[];
  }[] = [];
  public selectedFilters: FeedFilters = {
    storage: '',
    type: [],
    volumeRange: [0, 0],
    capacityRange: [0, 0],
    weightRange: [0, 0],
    weightCapacityRange: [0, 0],
    customSiteFilters: [],
    customEntityFilters: []
  };

  public feedTypes: FeedType[] = [];
  public updated = false;
  public filtersOnOpen: FeedFilters = cloneDeep(this.selectedFilters);

  public handleOpen() {
    this.filtersOnOpen = cloneDeep(this.selectedFilters);
  }

  public handleClose() {
    this.selectedFilters = cloneDeep(this.filtersOnOpen);
  }

  created() {
    FetchFeedTypes().then(data => {
      this.feedTypes = data;
    });
  }

  mounted() {
    if (this.propFilters) {
      this.selectedFilters = JSON.parse(JSON.stringify(this.propFilters));
    }
    if (this.selectedPropSiteFilters) {
      this.selectedFilters.customSiteFilters = this.selectedPropSiteFilters;
    }
    if (this.selectedPropTankFilters) {
      this.selectedFilters.customEntityFilters = this.selectedPropTankFilters;
    }
  }

  @Watch('selectedPropSiteFilters')
  sitePropFilterChanged() {
    if (this.selectedPropSiteFilters) {
      this.selectedFilters.customSiteFilters = this.selectedPropSiteFilters;
    }
  }

  @Watch('selectedPropTankFilters')
  tankPropFilterChanged() {
    if (this.selectedPropTankFilters) {
      this.selectedFilters.customEntityFilters = this.selectedPropTankFilters;
    }
  }

  @Watch('propFilters', { deep: true })
  filtersUpdated() {
    if (this.propFilters) {
      this.selectedFilters = this.propFilters;
    }
  }

  @Watch('maxVolume')
  maxVolumeLoaded(value: number, oldValue: number) {
    if (this.selectedFilters[getFeedAmountFilter()][1] == oldValue) {
      this.selectedFilters[getFeedAmountFilter()][1] = value;
    } else if (!this.selectedFilters[getFeedAmountFilter()][1]) {
      this.selectedFilters[getFeedAmountFilter()][1] = this.maxVolume;
    }
  }

  @Watch('maxCapacity')
  maxCapacityLoaded(value: number, oldValue: number) {
    if (this.selectedFilters[getFeedCapacityFilter()][1] == oldValue) {
      this.selectedFilters[getFeedCapacityFilter()][1] = value;
    } else if (!this.selectedFilters[getFeedCapacityFilter()][1]) {
      this.selectedFilters[getFeedCapacityFilter()][1] = this.maxCapacity;
    }
  }

  get listSiteCustomFields() {
    const filtered = JSON.parse(
      JSON.stringify(CustomStore.customFields ?? [])
    ).filter(
      (item: CustomField) => item.type == 'List Entry' && item.filterable
    );
    return filtered.map((item: CustomField) => {
      return {
        ...item,
        listOptions: item.listOptions?.sort((a, b) => {
          if (a != null && b != null) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else {
            return -1;
          }
        })
      };
    });
  }
  get listTankCustomFields() {
    const filtered = JSON.parse(
      JSON.stringify(CustomStore.customEntityFields)
    )?.filter(
      (item: CustomField) => item.type == 'List Entry' && item.filterable
    );
    return filtered.map((item: CustomField) => {
      return {
        ...item,
        listOptions: item.listOptions?.sort((a, b) => {
          if (a != null && b != null) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else if (a != null && b == null) {
            return -1;
          } else {
            return -1;
          }
        })
      };
    });
  }

  public isSelected(field: CustomField, selection: string, level: string) {
    let includes = false;
    if (level == 'site') {
      this.selectedFilters.customSiteFilters.forEach(filter => {
        if (
          filter.field.name == field.name &&
          filter.selection.includes(selection)
        ) {
          includes = true;
        }
      });
    } else if (level == 'tank') {
      this.selectedFilters.customEntityFilters.forEach(filter => {
        if (
          filter.field.name == field.name &&
          filter.selection.includes(selection)
        ) {
          includes = true;
        }
      });
    }
    return includes;
  }

  public addFilter(field: CustomField, selection: string, level: string) {
    this.updated = true;
    let includes = false;
    let selectionAlreadyInField = false;
    if (level == 'site') {
      this.selectedFilters.customSiteFilters.forEach(filter => {
        if (filter.field.name == field.name) {
          selectionAlreadyInField = true;
          if (filter.selection.includes(selection)) {
            includes = true;
          }
        }
      });
      if (!includes) {
        if (selectionAlreadyInField) {
          this.selectedFilters.customSiteFilters
            .find(filter => filter.field.name == field.name)
            ?.selection.push(selection);
        } else {
          this.selectedFilters.customSiteFilters.push({
            field: field,
            selection: [selection]
          });
        }
      } else {
        const selectedFilter = this.selectedFilters.customSiteFilters.find(
          filter => filter.field.name == field.name
        );
        if (
          selectedFilter?.selection &&
          selectedFilter?.selection.length == 1
        ) {
          this.selectedFilters.customSiteFilters = this.selectedFilters.customSiteFilters.filter(
            filter => filter.field.name != field.name
          );
        } else if (selectedFilter?.selection) {
          selectedFilter.selection = selectedFilter.selection.filter(
            select => selection != select
          );
        }
      }
    } else if (level == 'tank') {
      this.selectedFilters.customEntityFilters.forEach(filter => {
        if (filter.field.name == field.name) {
          selectionAlreadyInField = true;
          if (filter.selection.includes(selection)) {
            includes = true;
          }
        }
      });

      if (!includes) {
        if (selectionAlreadyInField) {
          this.selectedFilters.customEntityFilters
            .find(filter => filter.field.name == field.name)
            ?.selection.push(selection);
        } else {
          this.selectedFilters.customEntityFilters.push({
            field: field,
            selection: [selection]
          });
        }
      } else {
        const selectedFilter = this.selectedFilters.customEntityFilters.find(
          filter => filter.field.name == field.name
        );
        if (
          selectedFilter?.selection &&
          selectedFilter?.selection.length == 1
        ) {
          this.selectedFilters.customEntityFilters = this.selectedFilters.customEntityFilters.filter(
            filter => filter.field.name != field.name
          );
        } else if (selectedFilter?.selection) {
          selectedFilter.selection = selectedFilter.selection.filter(
            select => selection != select
          );
        }
      }
    }
  }

  public updateTypeFilter(newType: string) {
    if (newType == 'All Types') {
      this.selectedFilters.type = [];
    } else if (this.selectedFilters.type.includes(newType)) {
      this.selectedFilters.type = this.selectedFilters.type.filter(type => {
        return type != newType;
      });
    } else {
      this.selectedFilters.type.push(newType);
    }
  }

  public applyFilters(applyAndSave = false) {
    const alteredFilters: SavedFeedFilters = JSON.parse(
      JSON.stringify(this.selectedFilters)
    );
    if (this.selectedFilters.volumeRange[1] == this.maxVolume) {
      alteredFilters.volumeRange[1] = null;
    }
    if (this.selectedFilters.weightRange[1] == this.maxVolume) {
      alteredFilters.weightRange[1] = null;
    }
    if (this.selectedFilters.weightCapacityRange[1] == this.maxCapacity) {
      alteredFilters.weightCapacityRange[1] = null;
    }
    if (this.selectedFilters.capacityRange[1] == this.maxCapacity) {
      alteredFilters.capacityRange[1] = null;
    }
    if (
      alteredFilters.customEntityFilters == undefined &&
      (alteredFilters as any).customTankFilters != undefined
    ) {
      alteredFilters.customEntityFilters = (alteredFilters as any).customTankFilters;
      delete (alteredFilters as any).customTankFilters;
    }
    this.$emit(
      'feedFiltersUpdated',
      alteredFilters,
      this.selectedFilters,
      applyAndSave
    );
    this.closeFiltersModal();
  }
  public clearCustomFilters() {
    this.selectedFilters = {
      storage: '',
      type: [],
      volumeRange: [0, this.maxVolume],
      capacityRange: [0, this.maxCapacity],
      weightRange: [0, this.maxVolume],
      weightCapacityRange: [0, this.maxCapacity],
      customSiteFilters: [],
      customEntityFilters: []
    };
    this.$emit('clearFilters');
    this.applyFilters(false);
    this.closeFiltersModal();
  }

  public closeFiltersModal() {
    this.$root.$emit('bv::hide::modal', 'feedFiltersModal', '#btnHide');
  }
}
