
import RepairIcon from '@/components/common/RepairIcon.vue';
import { Product } from '@/store/models/Product';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component({
  components: {
    RepairIcon
  }
})
export default class FuelCardList extends Vue {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() loadingProducts!: boolean;

  public didSelectCard(selectedItems: Product[], id: number, vatId: number) {
    this.$emit('card-selected', selectedItems, id, vatId);
  }
}
