
import RepairIcon from '@/components/common/RepairIcon.vue';
import { ComplianceSettings } from '@/store/models/MilkScore';
import { MilkMetrics, Product } from '@/store/models/Product';
import { formatHours } from '@/utils/DateUtils';
import { formatMilkScoreDisplay } from '@/utils/formatters/DisplayValueFormatters';
import {
  complianceMethodLabel,
  getComplianceScore
} from '@/utils/MilkScoreUtils';
import { isMilkTempColumnPartnerId } from '@/utils/TableColumns';
import {
  formatAgeMilk,
  formatLastMilking,
  formatMilkScore,
  formatMilkScoreAbbr,
  formatMilkScoreColour
} from '@/utils/TableFormatters';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import AppName from '../mixin/AppName.vue';
import ConstantsMixin from '../mixin/Constants.vue';
import { getImage } from '@/utils/SvgUtils';

@Component({
  components: {
    RepairIcon
  },
  methods: {
    formatLastMilking,
    formatMilkScore,
    formatAgeMilk,
    formatMilkScoreAbbr,
    formatHours,
    isMilkTempColumnPartnerId,
    formatMilkScoreColour,
    formatMilkScoreDisplay,
    complianceMethodLabel,
    getImage
  }
})
export default class MilkCardView extends mixins(AppName, ConstantsMixin) {
  @PropSync('items') sortedProducts!: Product[];
  @Prop() combinedValues!: Product[];

  public milkScore(entity: Product) {
    return getComplianceScore(
      (entity?.metrics as MilkMetrics)?.milkScores,
      entity?.entityCustomSettingsData as ComplianceSettings
    );
  }

  public expanded = -1;

  public didSelectCard(selectedItems: Product[], id: number, vatId: number) {
    this.$emit('card-selected', selectedItems, id, vatId);
  }

  public toggleVats(productId: number) {
    if (this.expanded == productId) {
      this.expanded = -1;
    } else {
      this.expanded = productId;
    }
  }
}
